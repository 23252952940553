import { Hidden, Link } from '@material-ui/core';
import { AdvertenciaIcon, CheckTrackingIcon, RelojIcon, StepperTracking, Typography } from '@saesa/ui-kit-front';
import { AvisoPagoComercial, TextoInformativo } from 'components';
import { TipoColoresEnum, TipoEtapaTrackingEnum, TipoLargoLineaEnum, TipoPagoBoleta } from 'config';
import { AvisoPagoModel } from 'models';
import { EtapasModel } from 'models/EtapasModel';
import { HOME_PRIVADO_URL } from 'navigation';
import useStyles from 'pages/Styles/VistasCorteStyles';
import { Trans } from 'react-i18next';
import { FacturacionResponseModel, TrackingEstadosPorIdResponseModel } from 'services/models';
import {
  initModelosegundoTercerUltimoStepper,
  obtenerColorLinea,
  obtenerEstadoEntregaBoleta,
  obtenerEstadoTomaLectura,
  obtenerFechaEmisionCobroActual,
  obtenerFechaEmisionDatosAnexos,
  obtenerFechaEntregaBoleta,
  obtenerFechaEntregaBoletaTrackingCC,
  obtenerFechaFinLectura,
  obtenerFechaIncioLectura,
  obtenerFechaTrackingCC,
  obtenerFechaVenciminetoCobroActual,
  obtenerFlagBoletaEntregada,
  obtenerFlagDesdeBoletaEmitida,
  obtenerFlagEnviadaPorEmail,
  obtenerFlagPagada,
  obtenerFlagPorVencer,
  obtenerFlagVencido,
  obtenerIconoEntregaBoletaTrackingCC,
  obtenerIconoTomaLecturaTrackingCC,
  obtenerPrimerEstadoMostrarTooltipTrackingCC,
  obtenerSegundaTerceraCondicionStepper,
  obtenerTercerEstadoMostrarTooltipTrackingCC,
  obtenerTercerTextoMostrarTooltipTrackingCC,
} from 'utils';
import FormatValues from 'utils/FormatValues';
import { esDatoValido, formatoFecha } from 'utils/trackingUtils';

export const obtenerStepperTracking = (
  respuestaServicio: TrackingEstadosPorIdResponseModel,
  facturacion: FacturacionResponseModel,
  modeloPasos: EtapasModel[]
) => {
  /**
   * Componente styles
   */
  const styles = useStyles();
  return (
    <StepperTracking
      className={styles.stepper}
      modeloPasos={modeloPasos}
      mostrarRojoUltimaLinea={obtenerFlagVencido(respuestaServicio.estado, facturacion, facturacion.penultimaBoleta?.estadoPago)}
    />
  );
};

/**
 * Encargado de retornar estado timeInicial
 * @param respuestaServicio respuesta servicio estado por id
 * @param facturacion respuesta servicio facturacion
 * @param t componente traduccion
 * @returns primera time de stepper
 */
export const obtenerPrimerElementoTime = (
  respuestaServicio: TrackingEstadosPorIdResponseModel,
  facturacion: FacturacionResponseModel,
  t: any
) => {
  /**
   * Valores por defecto
   */
  const etapa: EtapasModel = {
    tamanioLinea: TipoLargoLineaEnum.MEDIUM,
    colorPaso: obtenerEstadoTomaLectura(respuestaServicio.estadosAnteriores),
    colorTitulo: TipoColoresEnum.GRIS_L43,
    colorFecha: TipoColoresEnum.GRIS_L43,
    colorLinea: obtenerColorLinea(facturacion.cobroActual?.estadoPago),
    titulo: t('tracking:paginas.tracking.cicloFacturacion.stepper.tomaLectura.titulo'),
    fecha: respuestaServicio.datos?.fechaInicioLectura && formatoFecha(respuestaServicio.datos.fechaInicioLectura),
    icono: RelojIcon,
    mostrarTooltip: false,
    textoTooltip: '',
  };
  /**
   * Valores dinamicos dependiendo del caso
   */
  if (respuestaServicio.estado === TipoEtapaTrackingEnum.EN_TOMA_LECTURA) {
    etapa.tamanioLinea = TipoLargoLineaEnum.LARGE;
    etapa.colorPaso = TipoColoresEnum.GRIS_L63;
    etapa.fecha = respuestaServicio.datos?.fechaRealTomaLectura && formatoFecha(respuestaServicio.datos.fechaRealTomaLectura);
  }
  if (respuestaServicio.estado === TipoEtapaTrackingEnum.LECTURA_EXITOSA) {
    etapa.colorPaso = TipoColoresEnum.VERDE_OSCURO;
    etapa.colorTitulo = TipoColoresEnum.VERDE_OSCURO;
    etapa.fecha = respuestaServicio.datos?.fechaRealTomaLectura && formatoFecha(respuestaServicio.datos.fechaRealTomaLectura);
    etapa.icono = CheckTrackingIcon;
  }
  if (respuestaServicio.estado === TipoEtapaTrackingEnum.LECTURA_NO_EXITOSA) {
    etapa.colorPaso = TipoColoresEnum.AMARILLO;
    etapa.colorTitulo = TipoColoresEnum.AMARILLO;
    etapa.fecha = '';
    etapa.icono = AdvertenciaIcon;
  }
  if (obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.colorPaso = obtenerEstadoTomaLectura(respuestaServicio.estadosAnteriores);
    etapa.colorLinea = TipoColoresEnum.VERDE_CLARO;
  }
  /**
   * Valores compartidos
   */
  if (
    respuestaServicio.estado !== TipoEtapaTrackingEnum.EN_PREPARACION &&
    respuestaServicio.estado !== TipoEtapaTrackingEnum.EN_TOMA_LECTURA
  ) {
    etapa.tamanioLinea = TipoLargoLineaEnum.FULL;
  }
  if (
    respuestaServicio.estado === TipoEtapaTrackingEnum.NOTIFICACION_BOLETA ||
    respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA ||
    respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA
  ) {
    etapa.colorLinea = obtenerColorLinea(facturacion.penultimaBoleta?.estadoPago);
  }
  if (
    respuestaServicio.estado === TipoEtapaTrackingEnum.NOTIFICACION_BOLETA ||
    respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA ||
    respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA ||
    obtenerFlagPagada(respuestaServicio.estado, facturacion)
  ) {
    etapa.fecha = obtenerFechaTrackingCC(respuestaServicio);
    etapa.icono = obtenerIconoTomaLecturaTrackingCC(respuestaServicio);
  }
  if (
    respuestaServicio.estado !== TipoEtapaTrackingEnum.EN_PREPARACION &&
    respuestaServicio.estado !== TipoEtapaTrackingEnum.EN_TOMA_LECTURA &&
    respuestaServicio.estado !== TipoEtapaTrackingEnum.LECTURA_EXITOSA
  ) {
    etapa.mostrarTooltip = obtenerPrimerEstadoMostrarTooltipTrackingCC(respuestaServicio);
    etapa.textoTooltip = t('tracking:paginas.tracking.cicloFacturacion.pagada.mensajesEtapa.tooltipTomaLectura');
  }
  return etapa;
};

/**
 * Encargado de retornar estado segundoTime
 * @param respuestaServicio respuesta servicio estado por id
 * @param facturacion respuesta servicio facturacion
 * @param t componente traduccion
 * @returns segundo time de stepper
 */
export const obtenerSegundoElementoTime = (
  respuestaServicio: TrackingEstadosPorIdResponseModel,
  facturacion: FacturacionResponseModel,
  t: any
) => {
  /**
   * Valores por defecto
   */
  const etapa = initModelosegundoTercerUltimoStepper(t('tracking:paginas.tracking.cicloFacturacion.stepper.emisionBoleta.titulo'));
  /**
   * Valores dinamicos dependiendo del caso
   */
  if (respuestaServicio.estado === TipoEtapaTrackingEnum.NOTIFICACION_BOLETA && !obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.colorTitulo = TipoColoresEnum.VERDE_OSCURO;
  }
  if (
    respuestaServicio.estado !== TipoEtapaTrackingEnum.EN_PREPARACION &&
    respuestaServicio.estado !== TipoEtapaTrackingEnum.EN_TOMA_LECTURA
  ) {
    etapa.colorFecha = TipoColoresEnum.GRIS_L43;
    etapa.colorLinea = obtenerColorLinea(facturacion.cobroActual?.estadoPago);
  }
  if (
    respuestaServicio.estado !== TipoEtapaTrackingEnum.EN_PREPARACION &&
    respuestaServicio.estado !== TipoEtapaTrackingEnum.EN_TOMA_LECTURA &&
    respuestaServicio.estado !== TipoEtapaTrackingEnum.NOTIFICACION_BOLETA
  ) {
    etapa.colorTitulo = TipoColoresEnum.GRIS_L43;
  }
  if (
    respuestaServicio.estado === TipoEtapaTrackingEnum.NOTIFICACION_BOLETA ||
    respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA ||
    respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA ||
    obtenerFlagPagada(respuestaServicio.estado, facturacion)
  ) {
    etapa.colorLinea = obtenerColorLinea(facturacion.penultimaBoleta?.estadoPago);
    etapa.fecha = facturacion.cobroActual?.fechaEmisionBoleta && formatoFecha(facturacion.cobroActual.fechaEmisionBoleta);
    etapa.icono = CheckTrackingIcon;
    etapa.colorPaso = TipoColoresEnum.VERDE_OSCURO;
  }
  if (obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.colorTitulo = TipoColoresEnum.GRIS_L43;
    etapa.fecha = formatoFecha(facturacion.ultimoPago.fechaEmision);
  }
  return etapa;
};

/**
 * Encargado de retornar estado tercerTime
 * @param respuestaServicio respuesta servicio estado por id
 * @param facturacion respuesta servicio facturacion
 * @param t componente traduccion
 * @returns tercer time de stepper
 */
export const obtenerTercerElementoTime = (
  respuestaServicio: TrackingEstadosPorIdResponseModel,
  facturacion: FacturacionResponseModel,
  t: any
) => {
  const etapa = initModelosegundoTercerUltimoStepper(t('tracking:paginas.tracking.cicloFacturacion.stepper.entregaBoleta.titulo'));
  const fechaVencimiento = facturacion.cobroActual?.fechaVencimiento;

  /**
   * Valores dinamicos dependiendo del caso
   */
  if (obtenerSegundaTerceraCondicionStepper(respuestaServicio)) {
    etapa.colorPaso = TipoColoresEnum.GRIS_L93;
    etapa.colorTitulo = TipoColoresEnum.GRIS_L93;
    etapa.colorFecha = TipoColoresEnum.GRIS_L93;
    etapa.colorLinea = TipoColoresEnum.GRIS_L93;
  }
  if (respuestaServicio.estado === TipoEtapaTrackingEnum.NOTIFICACION_BOLETA && !obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.tamanioLinea = TipoLargoLineaEnum.MEDIUM;
    etapa.colorPaso = TipoColoresEnum.GRIS_L63;
  }
  if (respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA) {
    etapa.colorPaso = TipoColoresEnum.VERDE_OSCURO;
    etapa.colorTitulo = TipoColoresEnum.VERDE_OSCURO;
    etapa.icono = CheckTrackingIcon;
  }
  if (respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA) {
    etapa.tamanioLinea = TipoLargoLineaEnum.FULL;
    etapa.colorPaso = TipoColoresEnum.AMARILLO;
    etapa.colorTitulo = TipoColoresEnum.AMARILLO;
    etapa.fecha = '';
    etapa.icono = AdvertenciaIcon;
    etapa.mostrarTooltip = true;
  }
  if (obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.colorPaso = obtenerEstadoEntregaBoleta(respuestaServicio.estadosAnteriores);
    etapa.colorLinea = TipoColoresEnum.VERDE_CLARO;
    etapa.icono = obtenerIconoEntregaBoletaTrackingCC(respuestaServicio);
    etapa.mostrarTooltip = obtenerTercerEstadoMostrarTooltipTrackingCC(respuestaServicio);
  }
  /**
   * Valores compartidos
   */
  if (
    respuestaServicio.estado === TipoEtapaTrackingEnum.NOTIFICACION_BOLETA ||
    obtenerFlagPagada(respuestaServicio.estado, facturacion) ||
    obtenerFlagVencido(respuestaServicio.estado, facturacion, fechaVencimiento)
  ) {
    etapa.colorTitulo = TipoColoresEnum.GRIS_L43;
  }
  if (
    respuestaServicio.estado === TipoEtapaTrackingEnum.NOTIFICACION_BOLETA ||
    respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA ||
    respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA
  ) {
    etapa.colorLinea = obtenerColorLinea(facturacion.penultimaBoleta?.estadoPago);
  }
  if (obtenerFlagDesdeBoletaEmitida(respuestaServicio, facturacion, fechaVencimiento)) {
    etapa.colorFecha = TipoColoresEnum.GRIS_L43;
  }
  if (respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA || obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.textoTooltip = obtenerTercerTextoMostrarTooltipTrackingCC(respuestaServicio, t);
  }
  if (respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA || obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.fecha = obtenerFechaEntregaBoletaTrackingCC(respuestaServicio);
  }
  return etapa;
};

/**
 * Encargado de retornar estado ultimoTime
 * @param respuestaServicio respuesta servicio estado por id
 * @param facturacion respuesta servicio facturacion
 * @param t componente traduccion
 * @returns ultimo time de stepper
 */
export const obtenerUltimoElementoTime = (
  respuestaServicio: TrackingEstadosPorIdResponseModel,
  facturacion: FacturacionResponseModel,
  t: any
) => {
  const fechaVencimiento = facturacion.cobroActual?.fechaVencimiento;
  /**
   * Valores por defecto
   */
  const etapa = initModelosegundoTercerUltimoStepper(t('tracking:paginas.tracking.cicloFacturacion.stepper.vencimientoBoleta.titulo'));
  if (obtenerSegundaTerceraCondicionStepper(respuestaServicio)) {
    etapa.colorPaso = TipoColoresEnum.GRIS_L93;
  }
  if (respuestaServicio.estado === TipoEtapaTrackingEnum.NOTIFICACION_BOLETA) {
    etapa.colorLinea = TipoColoresEnum.GRIS_L83;
  }
  if (obtenerFlagBoletaEntregada(respuestaServicio, facturacion, fechaVencimiento)) {
    etapa.tamanioLinea = TipoLargoLineaEnum.MEDIUM;
    etapa.colorPaso = TipoColoresEnum.GRIS_L63;
    etapa.colorTitulo = TipoColoresEnum.GRIS_L63;
    etapa.colorLinea = obtenerColorLinea(facturacion.penultimaBoleta?.estadoPago);
  }
  if (obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.colorPaso = TipoColoresEnum.VERDE_OSCURO;
    etapa.colorTitulo = TipoColoresEnum.VERDE_OSCURO;
    etapa.colorLinea = TipoColoresEnum.VERDE_CLARO;
    etapa.titulo = t('tracking:paginas.tracking.cicloFacturacion.stepper.pagoBoleta.titulo');
    etapa.fecha = facturacion.ultimoPago?.fechaBoleta && formatoFecha(facturacion.ultimoPago.fechaBoleta);
    etapa.icono = CheckTrackingIcon;
  }
  if (obtenerFlagVencido(respuestaServicio.estado, facturacion, fechaVencimiento)) {
    etapa.tamanioLinea = TipoLargoLineaEnum.FULL;
    etapa.colorLinea = TipoColoresEnum.VERDE_CLARO;
  }
  /**
   * Valores compartidos
   */

  /**
   *  Estados para el siguiente if
   */
  const estadosLecturaMedidor = [
    TipoEtapaTrackingEnum.EN_TOMA_LECTURA,
    TipoEtapaTrackingEnum.LECTURA_EXITOSA,
    TipoEtapaTrackingEnum.LECTURA_NO_EXITOSA,
    TipoEtapaTrackingEnum.NOTIFICACION_BOLETA,
  ];
  if (estadosLecturaMedidor.includes(respuestaServicio.estado) && !obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.colorTitulo = TipoColoresEnum.GRIS_L93;
    etapa.colorFecha = TipoColoresEnum.GRIS_L93;
  }

  /**
   *  Estados para el siguiente if
   */
  const estadosBoleta = [
    TipoEtapaTrackingEnum.NOTIFICACION_BOLETA,
    TipoEtapaTrackingEnum.BOLETA_ENTREGADA,
    TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA,
  ];

  if (estadosBoleta.includes(respuestaServicio.estado) && !obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.fecha = obtenerFechaVenciminetoCobroActual(facturacion);
  }
  /**
   *  Estados para el siguiente if
   */
  const estadosEntregaBoleta = [TipoEtapaTrackingEnum.BOLETA_ENTREGADA, TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA];

  if (estadosEntregaBoleta.includes(respuestaServicio.estado) || obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    etapa.colorFecha = TipoColoresEnum.GRIS_L63;
  }

  if (obtenerFlagPorVencer(respuestaServicio.estado, fechaVencimiento)) {
    etapa.tamanioLinea = TipoLargoLineaEnum.LARGE;
    etapa.colorFecha = TipoColoresEnum.GRIS_L43;
    etapa.colorTitulo = TipoColoresEnum.GRIS_L43;
    etapa.colorLinea = obtenerColorLinea(facturacion.penultimaBoleta?.estadoPago);
    etapa.titulo = t('tracking:paginas.tracking.cicloFacturacion.stepper.vencimientoBoleta.titulo');
    etapa.fecha = obtenerFechaVenciminetoCobroActual(facturacion);
    etapa.icono = RelojIcon;
  }
  if (obtenerFlagVencido(respuestaServicio.estado, facturacion, fechaVencimiento)) {
    etapa.icono = AdvertenciaIcon;
    etapa.colorLinea = obtenerColorLinea(facturacion.penultimaBoleta?.estadoPago);
    etapa.colorPaso = TipoColoresEnum.ROJO;
    etapa.colorTitulo = TipoColoresEnum.ROJO;
  }
  return etapa;
};

/**
 * Obtiene el texto del titulo y texto bajada
 * @param respuestaServicio respuesta servicio estado por id
 * @param facturacion respuesta servicio facturacion
 * @param t componente traduccion
 * @returns text medio
 */
export const obtenerTextoMedio = (respuestaServicio: TrackingEstadosPorIdResponseModel, facturacion: FacturacionResponseModel, t: any) => {
  const styles = useStyles();
  let textoTitulo = '';
  let textoBajada = <></>;
  const fechaVencimiento = facturacion.cobroActual?.fechaVencimiento;

  let obtenerTextoTituloEmitida = t('tracking:paginas.tracking.cicloFacturacion.entregaEmitida.mensajesEtapa.fisica.titulo');
  let obtenerTextoTituloEntregada = t('tracking:paginas.tracking.cicloFacturacion.boletaEntregada.mensajesEtapa.fisica.titulo');
  const esEnviadaDigital = obtenerFlagEnviadaPorEmail(respuestaServicio);

  if (esEnviadaDigital) {
    obtenerTextoTituloEmitida = t('tracking:paginas.tracking.cicloFacturacion.entregaEmitida.mensajesEtapa.digital.titulo');
    obtenerTextoTituloEntregada = t('tracking:paginas.tracking.cicloFacturacion.boletaEntregada.mensajesEtapa.digital.titulo');
  }

  const contenidoTransEnPreparacion = (
    <Trans
      i18nKey="tracking:paginas.tracking.cicloFacturacion.enPreparacion.mensajesEtapa.textoBajada"
      fechaDesde={obtenerFechaIncioLectura(respuestaServicio)}
      fechaHasta={obtenerFechaFinLectura(respuestaServicio)}
    >
      {{ fechaDesde: obtenerFechaIncioLectura(respuestaServicio) }}
      {{ fechaHasta: obtenerFechaFinLectura(respuestaServicio) }}
      <Link href={HOME_PRIVADO_URL} target="_blank">
        oficina virtual
      </Link>
      .
    </Trans>
  );
  const contenidoTransEnTomaLectura = (
    <Trans i18nKey="tracking:paginas.tracking.cicloFacturacion.tomaLectura.mensajesEtapa.textoBajada">
      Asegúrate de que alguien pueda facilitar la lectura de tu medidor. Para recibir un aviso el día de la visita, activa tus
      notificaciones desde la
      <Link href={HOME_PRIVADO_URL} target="_blank">
        oficina virtual
      </Link>
      .
    </Trans>
  );
  const contenidoTransLecturaExitosa = (
    <Trans
      i18nKey="tracking:paginas.tracking.cicloFacturacion.lecturaExitosa.mensajesEtapa.textoBajada"
      kwh={respuestaServicio.datos.consumoKWH}
    >
      Tu consumo fue de {{ kwh: respuestaServicio.datos.consumoKWH }} kWh. Tu boleta será emitida en los próximos días.
    </Trans>
  );
  const contenidoTransNoExitosa = (
    <Trans i18nKey="tracking:paginas.tracking.cicloFacturacion.lecturaSinExito.mensajesEtapa.textoBajada">
      Si te suscribiste a la autolectura, revisa tus notificaciones e ingrésala para evitar el cobro tus últimos 6 meses de consumo
      promediados.
    </Trans>
  );
  const contenidoTransBoletaEmitida = (
    <>
      <Hidden only={['xs', 'sm']}>
        <Trans
          i18nKey="tracking:paginas.tracking.cicloFacturacion.entregaEmitida.mensajesEtapa.fisica.textoBajada"
          fecha={obtenerFechaEmisionCobroActual(facturacion)}
        >
          Ya puedes descargar tu boleta y realizar el pago por tu servicio. La boleta física llegará a tu domicilio en los próximos días.
        </Trans>
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <Trans
          i18nKey="tracking:paginas.tracking.cicloFacturacion.entregaEmitida.mensajesEtapa.fisica.textoBajadaMobile"
          fecha={obtenerFechaEmisionCobroActual(facturacion)}
        >
          Ya puedes descargar tu boleta y realizar el pago por tu servicio. La boleta física llegará a tu domicilio en los próximos días.
        </Trans>
      </Hidden>
    </>
  );
  const contenidoTransBoletaEntregada = (
    <Trans
      i18nKey="tracking:paginas.tracking.cicloFacturacion.boletaEntregada.mensajesEtapa.fisica.textoBajada"
      fecha={obtenerFechaEmisionDatosAnexos(respuestaServicio)}
    >
      Tu boleta se entregó el día
      {{ fecha: obtenerFechaEntregaBoleta(respuestaServicio) }}
      <Link href={HOME_PRIVADO_URL} target="_blank">
        oficina virtual
      </Link>
      y realizar el pago por tu servicio. La boleta llegará a tu domicilio en los próximos días.
    </Trans>
  );
  const contenidoTransBoletaNoEntregada = (
    <Trans i18nKey="tracking:paginas.tracking.cicloFacturacion.boletaNoEntregada.mensajesEtapa.textoBajada">
      Pero no te preocupes, puedes ver tu boleta y recibirla mes a mes en tu correo electrónico suscribiéndote desde la{' '}
      <Link href={HOME_PRIVADO_URL} target="_blank">
        oficina <br /> virtual
      </Link>
      .
    </Trans>
  );

  /**
   * Obtener texto bajada de tipo emitida
   * @returns contenido boleta emitida
   */
  const obtenerTextoBajadaEmitida = obtenerFlagEnviadaPorEmail(respuestaServicio)
    ? t('tracking:paginas.tracking.cicloFacturacion.entregaEmitida.mensajesEtapa.digital.textoBajada', {
        fecha: obtenerFechaEmisionCobroActual(facturacion),
      })
    : contenidoTransBoletaEmitida;

  const obtenerTextoBajadaEntregada = obtenerFlagEnviadaPorEmail(respuestaServicio)
    ? t('tracking:paginas.tracking.cicloFacturacion.boletaEntregada.mensajesEtapa.digital.textoBajada', {
        fecha: obtenerFechaEmisionDatosAnexos(respuestaServicio),
      })
    : contenidoTransBoletaEntregada;

  switch (respuestaServicio.estado) {
    case TipoEtapaTrackingEnum.EN_PREPARACION:
      textoTitulo = t('tracking:paginas.tracking.cicloFacturacion.enPreparacion.mensajesEtapa.titulo');
      textoBajada = contenidoTransEnPreparacion;
      break;
    case TipoEtapaTrackingEnum.EN_TOMA_LECTURA:
      textoTitulo = t('tracking:paginas.tracking.cicloFacturacion.tomaLectura.mensajesEtapa.titulo');
      textoBajada = contenidoTransEnTomaLectura;
      break;
    case TipoEtapaTrackingEnum.LECTURA_EXITOSA:
      textoTitulo = t('tracking:paginas.tracking.cicloFacturacion.lecturaExitosa.mensajesEtapa.titulo');
      textoBajada = contenidoTransLecturaExitosa;
      break;
    case TipoEtapaTrackingEnum.LECTURA_NO_EXITOSA:
      textoTitulo = t('tracking:paginas.tracking.cicloFacturacion.lecturaSinExito.mensajesEtapa.titulo');
      textoBajada = contenidoTransNoExitosa;
      break;
    case TipoEtapaTrackingEnum.NOTIFICACION_BOLETA:
      textoTitulo = obtenerTextoTituloEmitida;
      textoBajada = <>{obtenerTextoBajadaEmitida}</>;
      break;
    case TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA:
    case TipoEtapaTrackingEnum.BOLETA_ENTREGADA:
      textoTitulo = t('tracking:paginas.tracking.cicloFacturacion.boletaNoEntregada.mensajesEtapa.titulo');
      textoBajada = contenidoTransBoletaNoEntregada;
      if (respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA) {
        textoTitulo = obtenerTextoTituloEntregada;
        textoBajada = <>{obtenerTextoBajadaEntregada}</>;
      }
      if (obtenerFlagPorVencer(respuestaServicio.estado, fechaVencimiento)) {
        textoTitulo = t('tracking:paginas.tracking.cicloFacturacion.porVencer.mensajesEtapa.titulo');
        textoBajada = t('tracking:paginas.tracking.cicloFacturacion.porVencer.mensajesEtapa.textoBajada');
      }
      if (obtenerFlagVencido(respuestaServicio.estado, facturacion, fechaVencimiento)) {
        textoTitulo = t('tracking:paginas.tracking.cicloFacturacion.vencida.mensajesEtapa.titulo');
        textoBajada = t('tracking:paginas.tracking.cicloFacturacion.vencida.mensajesEtapa.textoBajada');
      }
      break;
    default:
      break;
  }
  if (obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    textoTitulo = t('tracking:paginas.tracking.cicloFacturacion.pagada.mensajesEtapa.titulo');
    textoBajada = <></>;
  }
  return <TextoInformativo className={styles.textoInformativo} titulo={textoTitulo} textoBajada={textoBajada} />;
};

/**
 * Función para obtener el valor de esCobroActualVencido y esPenultimaBoletaVencida
 * @param param objeto con los valores
 * @returns boleanos con la respuesta
 */
const valoresObtenerStickyInferior = ({
  condicionPreEmision,
  condicionPostEmision,
  facturacion,
}: {
  condicionPreEmision: boolean;
  condicionPostEmision: boolean;
  facturacion: any;
}): { esCobroActualVencido: boolean; esPenultimaBoletaVencida: boolean } => {
  const esCobroActualVencido: boolean = condicionPreEmision && facturacion.cobroActual?.estadoPago === TipoPagoBoleta.VENCIDO;
  const esPenultimaBoletaVencida: boolean = condicionPostEmision && facturacion.penultimaBoleta?.estadoPago === TipoPagoBoleta.VENCIDO;

  return {
    esCobroActualVencido,
    esPenultimaBoletaVencida,
  };
};

export const obtenerStickyInferior = (respuestaServicio: TrackingEstadosPorIdResponseModel, facturacion: FacturacionResponseModel) => {
  const condicionPostEmision = [
    TipoEtapaTrackingEnum.NOTIFICACION_BOLETA,
    TipoEtapaTrackingEnum.BOLETA_ENTREGADA,
    TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA,
  ].includes(respuestaServicio.estado);

  const condicionPreEmision = [
    TipoEtapaTrackingEnum.EN_PREPARACION,
    TipoEtapaTrackingEnum.EN_TOMA_LECTURA,
    TipoEtapaTrackingEnum.LECTURA_EXITOSA,
    TipoEtapaTrackingEnum.LECTURA_NO_EXITOSA,
  ].includes(respuestaServicio.estado);

  let tipoPago = null;
  let monto = facturacion.cobroActual?.montoPago && facturacion.cobroActual.montoPago;
  const fechaVencimiento = facturacion.cobroActual?.fechaVencimiento;

  const { esCobroActualVencido, esPenultimaBoletaVencida } = valoresObtenerStickyInferior({
    condicionPreEmision,
    condicionPostEmision,
    facturacion,
  });

  const esFlagVencido = obtenerFlagVencido(respuestaServicio.estado, facturacion, fechaVencimiento);

  if (obtenerFlagPagada(respuestaServicio.estado, facturacion)) {
    tipoPago = TipoPagoBoleta.PAGADO;
    monto = facturacion.ultimoPago?.montoPago && facturacion.ultimoPago.montoPago;
  } else if (esCobroActualVencido || esPenultimaBoletaVencida || esFlagVencido) {
    tipoPago = TipoPagoBoleta.VENCIDO;
  } else {
    tipoPago = TipoPagoBoleta.PENDIENTE_PAGO;
  }

  /**
   * Componente styles
   */
  const styles = useStyles();
  /**
   * Modelo de aviso pago
   */
  const modeloAvisoPago: AvisoPagoModel = {
    fechaEmision: facturacion.cobroActual?.fechaEmisionBoleta && facturacion.cobroActual.fechaEmisionBoleta,
    fechaPago: facturacion.ultimoPago?.fechaBoleta && facturacion.ultimoPago.fechaBoleta,
    fechaVencimiento: facturacion.cobroActual?.fechaVencimiento && facturacion.cobroActual.fechaVencimiento,
    monto,
    tipoPago,
    linkPaperlessBoleta: facturacion.cobroActual?.linkPaperlessBoleta,
  };
  if (
    ((respuestaServicio.estado === TipoEtapaTrackingEnum.EN_PREPARACION ||
      respuestaServicio.estado === TipoEtapaTrackingEnum.EN_TOMA_LECTURA ||
      respuestaServicio.estado === TipoEtapaTrackingEnum.LECTURA_EXITOSA ||
      respuestaServicio.estado === TipoEtapaTrackingEnum.LECTURA_NO_EXITOSA) &&
      facturacion.cobroActual?.estadoPago === TipoPagoBoleta.VENCIDO) ||
    obtenerFlagPagada(respuestaServicio.estado, facturacion) ||
    ((condicionPostEmision || obtenerFlagPorVencer(respuestaServicio.estado, fechaVencimiento)) &&
      !obtenerFlagPagada(respuestaServicio.estado, facturacion))
  ) {
    return <AvisoPagoComercial className={styles.avisoPago} modeloAvisoPago={modeloAvisoPago} />;
  }
  return <></>;
};
/**
 * Obtiene el texto de sin información
 * @param styles estilos texto
 * @param t i18n
 * @returns string
 */
export const obtenerTextoSinInfo = (styles: any, t: any) => {
  const { sinInfo } = styles;
  return (
    <Typography className={sinInfo} strong="normal" variant="parrafo">
      {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.sinInformacion')}
    </Typography>
  );
};

/**
 * Obtiene un string válido
 * @param valor valor a mostrar
 * @param styles estilos texto
 * @param t i18n
 * @returns string
 */
export const obtenerTextoAvisoPagoCC = (valor: any, styles: any, t: any, strong: any) =>
  esDatoValido(valor) ? (
    <Typography className={styles.info} strong={strong} variant="parrafo">
      {valor}
    </Typography>
  ) : (
    obtenerTextoSinInfo(styles, t)
  );

/**
 * Obtiene componente interno aviso pago de estado pendiente de pago
 * @param fechaEmision fecha emision boleta
 * @param fechaVencimiento fecha vencimiento boleta
 * @param styles estilos componente
 * @param t i18n
 * @returns componente pendiente pago
 */
export const obtenerTextoAvisoPagoCCPendientePago = (fechaEmision: string, fechaVencimiento: string, styles: any, t: any) => (
  <>
    <section className={styles.vencimientoEmision}>
      <Typography className={styles.label} strong="semi-bold" variant="parrafo">
        {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.emision')}
      </Typography>
      {obtenerTextoAvisoPagoCC(formatoFecha(fechaEmision), styles, t, 'normal')}
    </section>
    <section className={styles.vencimiento}>
      <Typography className={styles.label} strong="semi-bold" variant="parrafo">
        {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.vencimiento')}
      </Typography>
      {obtenerTextoAvisoPagoCC(formatoFecha(fechaVencimiento), styles, t, 'normal')}
    </section>
  </>
);

/**
 * Obtiene seccion interna de aviso pago estado pagado
 * @param fechaPago fecha pago boleta
 * @param monto monto boleta
 * @param styles estilos componente
 * @param t i18n
 * @returns componente pendiente pago
 */
export const obtenerTextoAvisoPagoCCPagado = (fechaPago: string, monto: string, styles: any, t: any) => (
  <>
    <section className={styles.pagadoFecha}>
      <Typography className={styles.label} strong="semi-bold" variant="parrafo">
        {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.fechaPago')}
      </Typography>
      {obtenerTextoAvisoPagoCC(formatoFecha(fechaPago), styles, t, 'normal')}
    </section>
    <section className={styles.pagadoMonto}>
      <Typography className={styles.label} strong="semi-bold" variant="parrafo">
        {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.monto')}
      </Typography>
      {obtenerTextoAvisoPagoCC(FormatValues.money(monto), styles, t, 'semi-bold')}
    </section>
  </>
);
